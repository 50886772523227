import {useCallback, useEffect, useState} from 'react';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3 from 'web3';

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: process.env.INFURA_PROJECTID,
    },
  },
};

let web3Modal;
if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    providerOptions,
    cacheProvider: true,
  });
}

export default function useWalletModel() {
  const [web3Details, setWeb3Details] = useState({
    provider: undefined,
    address: undefined,
    library: undefined,
    chainId: 0,
    deactivate: () => {},
    activate: () => {},
    active: false,
  });

  const _handleConnection = useCallback(
    async (provider) => {
      if (!provider) {
        return;
      }
      const web3 = new Web3(provider);
      const chainId = await web3.eth.getChainId();
      const accounts = await web3.eth.getAccounts();

      setWeb3Details((currW3Details) => ({
        ...currW3Details,
        provider,
        address: accounts[0],
        chainId,
        library: web3,
        active: true,
      }));
    },
    [setWeb3Details]
  );

  const activate = useCallback(async () => {
    const provider = await web3Modal.connect();
    _handleConnection(provider);
  }, [_handleConnection]);

  const deactivate = useCallback(async () => {
    const provider = await web3Modal.disconnect();
    _handleConnection(provider);
  }, [_handleConnection]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      web3Modal.connect().then((provider) => {
        _handleConnection(provider);
      });
    }
  }, [_handleConnection]);

  useEffect(() => {
    const provider = web3Details?.provider;
    if (provider?.on) {
      const handleConnect = ({chainId}) => {
        setWeb3Details((currW3Details) => ({
          ...currW3Details,
          chainId,
          active: true,
        }));
      };

      const handleAccountsChanged = (accounts) => {
        setWeb3Details((currW3Details) => ({
          ...currW3Details,
          address: accounts[0],
          active: true,
        }));
      };

      const handleChainChanged = (chainId) => {
        setWeb3Details((currW3Details) => ({
          ...currW3Details,
          chainId,
          active: true,
        }));
      };

      const handleDisconnect = (/* error */) => {
        setWeb3Details((currW3Details) => ({
          ...currW3Details,
          chainId: null,
          active: false,
        }));
      };

      provider.on('connect', handleConnect);
      provider.on('accountsChanged', handleAccountsChanged);
      provider.on('chainChanged', handleChainChanged);
      provider.on('disconnect', handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener('connect', handleConnect);
          provider.removeListener('accountsChanged', handleAccountsChanged);
          provider.removeListener('chainChanged', handleChainChanged);
          provider.removeListener('disconnect', handleDisconnect);
        }
      };
    }

    return () => {};
  }, [web3Details.provider]);

  return {...web3Details, activate};
}
