import React from 'react';
import {
  AppBar,
  CssBaseline,
  IconButton,
  List,
  ListItem,
  Toolbar,
  Typography,
  Avatar,
  Popover,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Button,
} from '@mui/material';
import {useSelector} from 'react-redux';
import {makeStyles} from '@mui/styles';
import NotificationsIcon from '@mui/icons-material/Notifications';
import WalletIcon from '@mui/icons-material/AccountBalanceWallet';
import {deepOrange} from '@mui/material/colors';
import useWalletModel from '../../hooks/useWalletModal';
import useWeb3ReactWalletModal from '../../hooks/useWeb3ReactWalletModal';
import {Link} from 'react-router-dom';
import {LinkedIn, Telegram, Twitter} from '@mui/icons-material';
import AccountBalanceWallet from '@mui/icons-material/AccountBalanceWallet';
// import { Auth } from "aws-amplify";
import faw_logo from './faw_logo.png';

const drawerWidth = 230;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
  },
  // toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    borderRight: 'none',
    backgroundColor: '#1E3D54',
    paddingLeft: '15px',
  },
  // appBar: {
  //   [theme.breakpoints.up('sm')]: {
  //     width: `calc(100% - ${drawerWidth}px)`,
  //     marginLeft: drawerWidth,
  //   },
  // },
  grow: {
    display: 'flex',
    flexGrow: 1,
  },
  avatarOrange: {
    // color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));

function Container(props) {
  const {userContext} = useSelector((state) => state);
  const {activate, deactivate, active, address} = useWalletModel();
  const classes = useStyles();
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openProfileMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMobileDrawerToggle = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };
  const _logout = () => {
    // Auth.signOut()
    //   .then(() => {
    //     window.location.replace("/");
    //   })
    //   .catch(() => {});
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar style={{backgroundColor: 'transparent'}} className="appbar-header" position="fixed">
        <Toolbar>
          <img src={faw_logo} alt="faw" />
          <div className={classes.grow} />
          <div>
            {active ? (
              <Button
                variant="contained"
                className="faw-background-gradient"
                style={{marginTop: '30px'}}
                onClick={() => {
                  deactivate();
                }}
              >
                Disconnect
              </Button>
            ) : null}
          </div>
        </Toolbar>
      </AppBar>
      {props.children}
      <AppBar
        style={{backgroundColor: 'transparent'}}
        className="appbar-header"
        position="fixed"
        sx={{top: 'auto', bottom: 0}}
      >
        <Grid item xs={12} container justifyContent="center" alignItems="center" spacing={1}>
          <Grid item xs={8} container justifyContent="center" alignItems="center">
            <img src={faw_logo} alt="faw" />
          </Grid>
          <Grid item container xs={6} spacing={0} justifyContent="center" alignItems="center">
            <Grid item xs={2}>
              <Link style={{textDecoration: 'none', color: 'white'}} to="/home">
                HOME
              </Link>
            </Grid>
            <Grid item xs={2}>
              <Link style={{textDecoration: 'none', color: 'white'}} to="/home">
                ABOUT
              </Link>
            </Grid>
            <Grid item xs={2}>
              <Link style={{textDecoration: 'none', color: 'white'}} to="/home">
                CONTACT
              </Link>
            </Grid>
            <Grid item xs={2}>
              <Link style={{textDecoration: 'none', color: 'white'}} to="/withdraw">
                WITHDRAW
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <hr />
          </Grid>
          <Grid item container xs={8} justifyContent="space-between" alignItems="flex-start">
            <Grid item container xs={4} justifyContent="flex-start" alignItems="flex-start">
              <Typography variant="caption">© 2021 FanAnywhere. All rights reserved</Typography>
            </Grid>
            <Grid item container xs={4} justifyContent="flex-end" alignItems="flex-start">
              <IconButton style={{backgroundColor: '#37AEE2'}}>
                <LinkedIn />
              </IconButton>
              <IconButton style={{backgroundColor: '#37AEE2'}}>
                <Twitter />
              </IconButton>
              <IconButton style={{backgroundColor: '#37AEE2'}}>
                <Telegram />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
    </div>
  );
}

Container.propTypes = {};

export default Container;
