import contract from '@truffle/contract';
import {useState} from 'react';
import useWalletModel from './useWalletModal';

export default function useContractInstance() {
  const {address: account, provider} = useWalletModel();
  const [contractJsons, setContractsJsons] = useState({
    FanTokenIssuanceJson: null,
  });
  const [issuanceInstance, setFanTokenIssuanceInstance] = useState(null);

  const {FanTokenIssuanceJson} = contractJsons;
  if (!FanTokenIssuanceJson) {
    if (process.env.REACT_APP_ENV === 'TEST') {
      import('../truffle/build/contracts/test/FanTokenIssuance.json').then((res) => {
        setContractsJsons({
          ...contractJsons,
          FanTokenIssuanceJson: res,
        });
      });
    } else {
      import('../truffle/build/contracts/dev/FanTokenIssuance.json').then((res) => {
        setContractsJsons({
          ...contractJsons,
          FanTokenIssuanceJson: res,
        });
      });
    }
    return {};
  }

  const FanTokenIssuanceContract = contract(FanTokenIssuanceJson);

  if (!provider || !account) {
    return {};
  }
  FanTokenIssuanceContract.setProvider(provider);

  FanTokenIssuanceContract.defaults({from: account});

  if (issuanceInstance) {
    return {issuanceInstance};
  }

  Promise.all([FanTokenIssuanceContract.deployed()])
    .then(([issuanceInst]) => {
      setFanTokenIssuanceInstance(issuanceInst);
    })
    .catch((error) => {
      console.log('BLOCKCHAIN ERROR:', error);
    });

  return {issuanceInstance, account};

  // console.log('<=== E2E: Registering To Auction Events ===>');
  // _registerEvents(auctionInstance);

  // console.log('<=== E2E: Miniting Token ===>');
  // const tokenId = await _mintToken(nftInstance, account);

  // console.log('<=== E2E: Approving Token to Auction ===>');
  // await _approveAuction(nftInstance, auctionInstance, tokenId);

  // console.log('<=== E2E: Registering Token Auction ===>');
  // const auctionId = await _registerAuction(nftInstance, auctionInstance, tokenId);

  // console.log(auctionId);

  // console.log('<=== E2E: Place Bid ===>');
  // await _placeBid(auctionInstance, auctionId);
}
