import React from 'react';
import {Web3ReactProvider} from '@web3-react/core';
import Web3 from 'web3';
import {Switch, Route, Redirect} from 'react-router-dom';
import Container from './components/Container';
import Home from './components/Home/Home';
import Withdraw from './components/Withdraw/Withdraw';

function getLibrary(provider) {
  return new Web3(provider);
}

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Container>
        <Switch>
          <Route path="/home" component={Home} />
          <Route path="/withdraw" component={Withdraw} />
          <Redirect to="/home" />
        </Switch>
      </Container>
    </Web3ReactProvider>
  );
}

export default App;
