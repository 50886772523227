import React from 'react';
import {Grid, Typography, Paper, Button} from '@mui/material';
import etheriumCoin from './ethereum-coin 1.png';
import {AccountBalanceWallet} from '@mui/icons-material';
import useWalletModel from '../../hooks/useWalletModal';
import {Redirect} from 'react-router';

function Home() {
  const {activate, deactivate, active, address} = useWalletModel();

  if (active) {
    return <Redirect to="/withdraw" />;
  }

  return (
    <div style={{display: 'flex', width: '100%'}}>
      <main
        style={{
          flexGrow: 1,
        }}
      >
        <Paper className="home-body">
          <Grid container spacing={2} style={{height: '100%'}}>
            <Grid
              item
              xs={6}
              container
              direction="column"
              alignSelf="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography className="faw-text-gradient" variant="h2">
                  Withdraw your Tokens
                </Typography>
                {/* </Grid>
              <Grid item xs={12}> */}
                <Typography variant="body2">
                  You must connect to a wallet to withdraw your token.
                </Typography>
                {/* </Grid>
              <Grid item xs={12}> */}
                <Button
                  variant="contained"
                  startIcon={<AccountBalanceWallet />}
                  className="faw-background-gradient"
                  style={{marginTop: '30px'}}
                  onClick={() => {
                    activate();
                  }}
                >
                  Connect Wallet
                </Button>
              </Grid>
            </Grid>
            <Grid alignSelf="center" alignItems="center" item xs={6}>
              <img src={etheriumCoin} alt="etheriumCoin" />
            </Grid>
          </Grid>
        </Paper>
      </main>
    </div>
  );
}

export default Home;
