import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {createTheme, ThemeProvider, styled} from '@mui/material/styles';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import 'typeface-rubik';
import 'typeface-roboto';

const theme = createTheme({
  // palette: {
  //   background: {
  //     default: '',
  //   },
  //   primary: {
  //     main: '#25B67D',
  //   },
  //   // text: {
  //   //   primary: '#243238',
  //   //   secondary: 'rgb(36,50,56, 0.6)',
  //   //   hint: 'rgb(36,50,56, 0.6)',
  //   //   // disabled: 'rgba(0, 0, 0, 0.7)',
  //   // },
  // },
  typography: {
    h2: {
      fontFamily: 'Rubik',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '36px',
      lineHeight: '43px',
    },
    body2: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '18px',
      lineHeight: '21px',
      color: '#F6F6F6',
    },
    subtitle1: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#f6f6f6',
      opacity: '0.75',
    },
    subtitle2: {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '900',
      lineHeight: '14px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#f6f6f6',
      opacity: '0.75',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        fontFamily: 'Rubik',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '21px',
        letterSpacing: '0em',
        textAlign: 'left',
        textTransform: 'none',
      },
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
