import React, {useEffect, useState} from 'react';
import {
  Grid,
  Typography,
  Paper,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  InputLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  FilledInput,
  FormControl,
} from '@mui/material';
import web3 from 'web3';
import {OpenInNew} from '@mui/icons-material';
import etheriumCoin from './ethereum-coin 1.png';
import EtherIcon from './ether.png';
import WalletIcon from './wallet.png';
import useWalletModel from '../../hooks/useWalletModal';
import useContractInstance from '../../hooks/useContractInstance';

function Home() {
  const {activate, deactivate, active, address} = useWalletModel();

  const {issuanceInstance} = useContractInstance();

  const [withdrawable, setWithdrawable] = useState(0);
  const [withdrawn, setWithdrawn] = useState(0);
  const [openWithdrawDialog, setOpenWithdrawDialog] = React.useState(false);

  useEffect(() => {
    if (issuanceInstance) {
      issuanceInstance
        .getWithdrawnAmount()
        .then((amt) => {
          setWithdrawn(parseInt(amt, 10));
        })
        .catch(() => {});
      issuanceInstance
        .getWithdrawableAmount()
        .then((amt) => {
          setWithdrawable(parseInt(amt, 10));
        })
        .catch(() => {});
    }
  }, [issuanceInstance]);

  const _withdrawDialog = () => {
    const [withdrawAmt, setWithdrawAmt] = useState(0);
    return (
      <Dialog
        PaperProps={{
          style: {
            backgroundColor:
              'linearGradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #121212;',
          },
        }}
        open={openWithdrawDialog}
        onClose={() => {
          setOpenWithdrawDialog(false);
        }}
      >
        <DialogTitle>Withdraw Tokens</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please confirm you want to withdraw {(withdrawable / 10 ** 18).toFixed(18)} FAW .
          </DialogContentText>
          {/* <TextField
            variant="outlined"
            id="token-amt"
            fullWidth
            type="number"
            value={withdrawAmt}
            onChange={(e) => {
              setWithdrawAmt(parseFloat(e.target.value));
            }}
            aria-describedby="token-amt"
            inputProps={{
              'aria-label': 'amount',
            }}
            InputProps={{endAdornment: <InputAdornment position="end">ETH</InputAdornment>}}
            label="Amount"
            helperText={
              withdrawAmt === 0 || !withdrawAmt
                ? 'Mandatory'
                : withdrawAmt < 0
                ? 'Enter a valid amount to withdraw'
                : 'USD$0000'
            }
          /> */}
        </DialogContent>
        <DialogActions>
          <Button
            className="faw-text-gradient"
            style={{color: '#c4c4c4'}}
            variant="outlined"
            onClick={() => {
              setWithdrawAmt(0);
              setOpenWithdrawDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="faw-background-gradient"
            variant="contained"
            onClick={async () => {
              try {
                await issuanceInstance.withdrawTokens();
              } catch (error) {
                console.log(error);
              }
              issuanceInstance
                .getWithdrawnAmount()
                .then((amt) => {
                  setWithdrawn(parseInt(amt, 10));
                })
                .catch(() => {});
              issuanceInstance
                .getWithdrawableAmount()
                .then((amt) => {
                  setWithdrawable(parseInt(amt, 10));
                })
                .catch(() => {});
              setWithdrawAmt(0);
              setOpenWithdrawDialog(false);
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div style={{display: 'flex', width: '100%'}}>
      <main
        style={{
          flexGrow: 1,
        }}
      >
        <Paper className="withdraw-body">
          <Grid container spacing={2} style={{height: '100%'}}>
            <Grid
              item
              xs={6}
              container
              direction="column"
              alignSelf="center"
              // alignItems="center"
              spacing={2}
            >
              <Grid
                item
                container
                justifyContent="space-between"
                // textAlign="center"
                alignItems="center"
                xs={7}
                marginLeft={20}
              >
                <Grid item xs={6}>
                  <Typography variant="h2" className="faw-text-gradient">
                    Token Summary
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    disabled={!address}
                    startIcon={<OpenInNew />}
                    variant="text"
                    style={{color: '#f6f6f6'}}
                    size="small"
                    href={`https://bscscan.com/address/${address}`}
                  >
                    Binance Explorer
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={7} marginLeft={20}>
                <Chip
                  variant="filled"
                  style={{color: '#f6f6f6', backgroundColor: 'rgba(196, 196, 196,15%)'}}
                  label={address}
                />
              </Grid>
              <Grid item xs={7} style={{width: 'inherit'}}>
                <Paper
                  sx={{p: 2, margin: 'auto', maxWidth: 500, flexGrow: 1}}
                  style={{backgroundColor: 'rgba(196, 196, 196,15%)'}}
                >
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <img src={EtherIcon} alt="ether" />
                    </Grid>
                    <Grid item xs={12} sm container>
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                          <Typography variant="h2" color="#f6f6f6">
                            Balance
                          </Typography>
                          <Typography className="token-value" color="#f6f6f6">
                            {(withdrawable / 10 ** 18).toFixed(18)}
                            <Typography variant="h2" color="#f6f6f6">
                              FAW
                            </Typography>
                          </Typography>
                          {/* <Typography variant="subtitle1">$0000.00</Typography> */}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        className="faw-background-gradient"
                        style={{marginTop: '30px'}}
                        onClick={() => {
                          setOpenWithdrawDialog(true);
                        }}
                      >
                        Withdraw
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={7} style={{width: 'inherit'}}>
                <Paper
                  sx={{p: 2, margin: 'auto', maxWidth: 500, flexGrow: 1}}
                  style={{backgroundColor: 'rgba(196, 196, 196,15%)'}}
                >
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <img src={WalletIcon} alt="ether" />
                    </Grid>
                    <Grid item xs={12} sm container>
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                          <Typography variant="h2" color="#f6f6f6">
                            Withdrawn
                          </Typography>
                          <Typography className="token-value" color="#f6f6f6">
                            {(withdrawn / 10 ** 18).toFixed(18)}
                          </Typography>
                          <Typography variant="h2" color="#f6f6f6">
                            FAW
                          </Typography>
                          {/* <Typography variant="subtitle1">$0000.00</Typography> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid alignSelf="center" alignItems="center" item xs={6}>
              <img src={etheriumCoin} alt="etheriumCoin" />
            </Grid>
          </Grid>
        </Paper>
      </main>
      {_withdrawDialog()}
    </div>
  );
}

export default Home;
